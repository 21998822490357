<template>
	<div class="mb-2 flex w-full flex-col gap-2.5">
		<div class="flex h-4 w-full rounded-2xl border border-grey-very-light p-0.5">
			<span
				class="h-full rounded-2xl bg-cta"
				:style="{ width: CartStore.gramsToCartLimitPercentage + '%' }"
			></span>
		</div>
		<div class="text-right text-sm font-semibold">
			Bag is {{ Math.round(CartStore.gramsToCartLimitPercentage) }}% full
		</div>
	</div>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cartStore';
const CartStore = useCartStore();
</script>
